import request, { DataApiResult } from '@/libs/http-request'
import { downfile } from '@/libs/request'

/**
 * 证书工具
 */
class CertMangerApi {
    private baseUrlPath='/api/maintain/certmanger/'

    /**
   * 下载证书文件
   * @returns
   */
    public downRootCertFile () {
      return downfile({
        method: 'GET',
        url: `${this.baseUrlPath}downRootCertFile`
      }, 'text/plain')
    }

    /**
   * 重新生成服务器的证书
   * @param pcServerId
   * @returns
   */
    public reCreateServerCertData (pcServerId: number) {
      return request<DataApiResult<{pwd: string; data: string}>>({
        url: `${this.baseUrlPath}reCreateServerCertData/${pcServerId}`,
        method: 'GET'
      })
    }

    /**
     * 重新生成consul的root证书
     * @returns
     */
    public reCreateConsulRootCertData () {
      return downfile({
        method: 'GET',
        url: `${this.baseUrlPath}reCreateConsulRootCertData`
      }, 'application/x-tar')
    }
}

export default new CertMangerApi()
